<script>
import Vue from 'vue'
import Sigma from '@sigmacloud/sigma-client'
import CheckResource from '@sigmacloud/sigma-client/dist/resources/transactions/check'
import BaseResource from '@sigmacloud/sigma-client/dist/resources/base'
import EmployeeResource from '@sigmacloud/sigma-client/dist/resources/system/employee'
import PayrollResource from '@sigmacloud/sigma-client/dist/resources/transactions/pr/index'
import ClientResource from '@sigmacloud/sigma-client/dist/resources/system/client'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import Autocomplete from './Autocomplete.vue'
import { AccountsPayableResource } from '@sigmacloud/sigma-client/dist/resources'
import UserMixin from '../mixins/UserMixin'
import CheckMixins from '../mixins/CheckMixins'
import ErrorsMixins from '../mixins/ErrorsMixins'
import adjustment from '../constants/adjustment.constants'

export default Vue.extend({
    components: { Autocomplete },
    data() {
        return {
            checkList: undefined,
            checkListAttributes: [],
            checkListNegotiator: undefined,
            loading: false,
            dateFrom: undefined,
            dateTo: undefined,
            checkNumberFrom: undefined,
            checkNumberTo: undefined,
            employeeResource: undefined,
            tinResource: undefined,
            payrollResource: undefined,
            clientResource: undefined,
            projectResource: undefined,
            tableKey: 0,
            currentPage: 1,
            rows: 0,
            perPage: 20,
            newPrs: [],
            fields: [{ label: 'Country', key: 'currency_data.country_name' }, { label: 'Bank', key: 'batch_data.bank_name' }, { label: 'Check number', key: 'number' }, 'payroll', 'payee_name', 'amount_decimal', 'date', 'description', 'status', 'adjustment', 'submit'],
            selectOptions: adjustment.ADJUSTMENTS_OPT,
            adjustments: adjustment.ADJUSTMENTS,
            selectedAdjustment: {
                name: undefined,
                ap: undefined,
            },
            adjustmentBusy: false,
        }
    },
    computed: {
        resources() {
            return {
                CheckResource,
                ClientResource,
                EmployeeResource,
                PayrollResource,
                ProjectResource,
            }
        },
    },
    mixins: [UserMixin, CheckMixins, ErrorsMixins],
    methods: {
        logger(item) {
            console.log(item)
        },
        async populateCheckTable() {
            this.loading = true
            try {
                let checkListNegotiator = await CheckResource.list()
                this.checkListNegotiator = checkListNegotiator
                this.rows = checkListNegotiator.count
                let checkList = [...checkListNegotiator.resources]
                for (let check of checkList) {
                    let relatedAp = await AccountsPayableResource.detail(check.attributes.related_transactions[0])
                    check.attributes.payroll = relatedAp.attributes.payroll
                }
                this.checkList = checkList
                if (this.checkList) {
                    this.extractAttributes()
                }
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },
        extractAttributes() {
            this.checkListAttributes = []
            for (let check of this.checkList) {
                this.checkListAttributes.push(check._attributes)
            }
            this.tableKey++
            this.$refs.checkTable.refresh()
        },
        async submitAdjustment(data) {
            // console.log(data)
            let endpoint
            if (this.selectedAdjustment.name && this.selectedAdjustment.ap) {
                let adjustmentInfo = this.adjustments.find((adjustment) => {
                    return adjustment.name === this.selectedAdjustment.name
                })
                endpoint = `/aps/${this.selectedAdjustment.ap}${adjustmentInfo.endpoint}`
            } else {
                return
            }
            try {
                this.loading = true
                let res = BaseResource.wrap(endpoint)
                let response = await res.post()
                if (this.selectedAdjustment.name === adjustment.VOID_REISSUE) {
                    let newApId = response.data.accountspayable[0]
                    let newApResource = await AccountsPayableResource.detail(newApId)
                    let selectedPayrollResource = await PayrollResource.detail(newApResource.attributes.payroll)
                    let groupedPayrolls = await this.groupPayrollsByBank([selectedPayrollResource.attributes])
                    let numberedChecks = await this.getCheckNumbers(groupedPayrolls)
                    // console.log(numberedChecks)
                    let savedChecks = await this.saveChecks(numberedChecks, true)
                    await this.saveCheckRun(savedChecks)
                    let newPr = {
                        prId: newApResource.attributes.payroll,
                        adjustment: this.selectedAdjustment.name,
                    }
                    this.$emit('message', 'The reissue check has been created successfully.  You may print it from the Print Paychecks interface.')
                } else if (this.selectedAdjustment.name === adjustment.STRAIGHT_VOID) {
                    // This is a Void & Credit Client
                    // New PR is response.data.credit_payroll
                    this.goToNewPr(response.data.credit_payroll)
                }
                return response
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },
        goToNewPr(event) {
            this.$router.push({ path: `/payroll/${event}` })
        },
        dismissMessage(event) {
            let messageIndex = this.newPrs.findIndex((element, index) => {
                if (element.payroll === event.payroll) {
                    return index
                }
            })
            this.newPrs = this.newPrs.splice(messageIndex, 0)
        },
        selectAdjustment(data) {
            // console.log(data)
            this.selectedAdjustment.name = data.value
            this.selectedAdjustment.ap = parseInt(this.checkListAttributes[data.index].related_transactions[0])
        },
        selectClient(data) {
            this.clientResource = data
        },
        selectEmployee(data) {
            this.employeeResource = data
        },
        selectPayroll(data) {
            this.payrollResource = data
        },
        selectProject(data) {
            this.projectResource = data
        },
        clearSearchCriteria() {
            this.clientResource = undefined
            this.employeeResource = undefined
            this.payrollResource = undefined
            this.projectResource = undefined
            this.dateFrom = undefined
            this.dateTo = undefined
            this.checkNumberFrom = undefined
            this.checkNumberTo = undefined
        },
        async searchForChecks(pageNum) {
            let apQueryObj = {}
            let checkQueryObj = {
                number__gte: undefined,
                number__lte: undefined,
                date__gte: undefined,
                date__lte: undefined,
                related_transactions: [],
            }
            let apQuery = undefined
            let checkQuery = undefined
            let apQueryResults = undefined
            let checkQueryResults = undefined
            if (this.clientResource) {
                apQueryObj.client = this.clientResource.id
            }
            if (this.employeeResource) {
                apQueryObj.employee = this.employeeResource.id
            }
            if (this.payrollResource) {
                apQueryObj.payroll = this.payrollResource.id
            }
            if (this.projectResource) {
                apQueryObj.project = this.projectResource.id
            }
            if (this.checkNumberFrom) {
                checkQueryObj.number__gte = this.checkNumberFrom
            }
            if (this.checkNumberTo) {
                checkQueryObj.number__lte = this.checkNumberTo
            }
            if (this.dateFrom) {
                checkQueryObj.date__gte = this.dateFrom
            }
            if (this.dateTo) {
                checkQueryObj.date__lte = this.dateTo
            }

            if (Object.keys(apQueryObj).length === 0) {
                apQuery = false
            } else {
                apQuery = true
            }

            if (apQuery) {
                // console.log(apQueryObj)
                try {
                    this.loading = true
                    apQueryResults = await AccountsPayableResource.filter(apQueryObj)
                    // console.log(apQueryResults)
                    for (let resource of apQueryResults.resources) {
                        checkQueryObj.related_transactions.push(resource.id)
                    }
                } catch (error) {
                    this.$emit('message', error)
                } finally {
                    this.loading = false
                }
            }

            if (Object.keys(checkQueryObj).length === 0) {
                checkQuery = false
            } else {
                checkQuery = true
            }

            if (checkQuery) {
                let cqObj = {}
                let apIdString = ''
                for (let key of Object.keys(checkQueryObj)) {
                    if (key !== 'related_transactions' && checkQueryObj[key] !== undefined) {
                        cqObj[key] = checkQueryObj[key]
                    }
                }
                if (checkQueryObj.related_transactions.length > 0) {
                    for (let apId of checkQueryObj.related_transactions) {
                        apIdString += `${apId},`
                    }
                    //remove trailing comma
                    apIdString = apIdString.substring(0, apIdString.length - 1)
                    // console.log(apIdString)
                }
                if (apIdString.length > 0) {
                    cqObj.related_transactions__in = apIdString
                }

                if (pageNum && typeof pageNum === 'number') {
                    cqObj.page = pageNum
                }

                try {
                    this.loading = true
                    checkQueryResults = await CheckResource.filter(cqObj)
                    // console.log(checkQueryResults)
                    for (let check of checkQueryResults.resources) {
                        let relatedAp = await AccountsPayableResource.detail(check.attributes.related_transactions[0])
                        check.attributes.payroll = relatedAp.attributes.payroll
                    }

                    this.checkList = [...checkQueryResults.resources]
                    this.rows = checkQueryResults.count
                    this.extractAttributes()
                } catch (error) {
                    this.$emit('message', error)
                } finally {
                    this.loading = false
                }
            }
        },

        async changePage(pageNum) {
            try {
                this.loading = true
                await this.searchForChecks(pageNum)
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },

        clearAutocomplete(ref) {
            let autocompleteNameComponents = ref.split('-')
            this.$refs[ref].clear()
            let resourceName = `${autocompleteNameComponents[0]}Resource`
            this[resourceName] = undefined
        },

        resetAllFilters() {
            this.$refs['employee-autocomplete'].clear()
            this.$refs['client-autocomplete'].clear()
            this.$refs['payroll-autocomplete'].clear()
            this.$refs['project-autocomplete'].clear()

            this.employeeResource = undefined
            this.clientResource = undefined
            this.payrollResource = undefined
            this.projectResource = undefined

            this.dateFrom = undefined
            this.dateTo = undefined
            this.checkNumberFrom = undefined
            this.checkNumberTo = undefined

            this.populateCheckTable()
        },
    },
})
</script>

<template>
    <div>
        <h1>Adjustments</h1>
        <b-container fluid>
            <b-row>
                <b-col class="d-flex justify-content-center">
                    <b-spinner v-if="loading"></b-spinner>
                </b-col>
            </b-row>
            <b-row cols="6" class="mb-2">
                <b-col>
                    <b-btn block href="#" v-b-toggle.accordion1 variant="secondary">
                        Search &amp; Filters
                        <span class="when-opened">
                            <b-icon icon="chevron-down" />
                        </span>
                        <span class="when-closed">
                            <b-icon icon="chevron-right" />
                        </span>
                    </b-btn>
                </b-col>
            </b-row>
            <b-row class="mb-2" cols="3">
                <b-col>
                    <b-collapse id="accordion1" visible role="tabpanel">
                        <b-row>
                            <b-col>
                                <label>Check # from: </label>
                            </b-col>
                            <b-col>
                                <label>Check # to:</label>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <input v-model="checkNumberFrom" />
                            </b-col>
                            <b-col>
                                <input v-model="checkNumberTo" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label>Check date from:</label>
                            </b-col>
                            <b-col>
                                <label>Check date to:</label>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <input type="date" v-model="dateFrom" />
                            </b-col>
                            <b-col>
                                <input type="date" v-model="dateTo" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="d-flex justify-content-end">
                                <label for="employee-autocomplete">Employee</label>
                            </b-col>
                            <b-col class="col-8">
                                <autocomplete name="employee-autocomplete" ref="employee-autocomplete" :resourceClass="resources.EmployeeResource" @select="selectEmployee($event)">
                                    <template slot="item" slot-scope="option">
                                        <span v-if="option.item.resource && option.item.resource.attributes.is_loanout">
                                            {{ option.item.resource.attributes.loanout_name }}
                                        </span>
                                        <p v-if="option.item.resource">
                                            {{ option.item.resource.attributes.last_name }}, {{ option.item.resource.attributes.first_name }}<br />
                                            Code: {{ option.code }} - <span v-if="option.item.resource.attributes.is_loanout == true">FEIN: {{ option.item.resource.attributes.fein_last_4 }}</span>
                                            <span> / TIN: {{ option.item.resource.attributes.masked_tin }}</span>
                                        </p>
                                        <p v-else>{{ option.code }}</p>
                                    </template>
                                </autocomplete>
                            </b-col>
                            <b-col class="col-1">
                                <b-button @click="clearAutocomplete('employee-autocomplete')" variant="light">
                                    <b-icon icon="x-circle" variant="danger"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="d-flex justify-content-end">
                                <label for="payroll-autocomplete">Payroll</label>
                            </b-col>
                            <b-col class="col-8">
                                <autocomplete name="payroll-autocomplete" ref="payroll-autocomplete" :resourceClass="resources.PayrollResource" display-prop="id" secondary-display-prop="description" @select="selectPayroll($event)">
                                    <template slot="item" slot-scope="option">
                                        <!-- {{ logger(option.item) }} -->
                                        <span v-if="option.item.resource"> {{ option.item.resource.id }} - {{ option.item.resource.attributes.description }} </span>
                                        <p v-else>{{ option.code }}</p>
                                    </template>
                                </autocomplete>
                            </b-col>
                            <b-col class="col-1">
                                <b-button @click="clearAutocomplete('payroll-autocomplete')" variant="light">
                                    <b-icon icon="x-circle" variant="danger"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="d-flex justify-content-end">
                                <label for="client-autocomplete">Client</label>
                            </b-col>
                            <b-col class="col-8">
                                <autocomplete name="client-autocomplete" ref="client-autocomplete" :resourceClass="resources.ClientResource" @select="selectClient($event)"> </autocomplete>
                            </b-col>
                            <b-col class="col-1">
                                <b-button @click="clearAutocomplete('client-autocomplete')" variant="light">
                                    <b-icon icon="x-circle" variant="danger"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col class="d-flex justify-content-end">
                                <label for="project-autocomplete">Project</label>
                            </b-col>
                            <b-col class="col-8">
                                <autocomplete name="project-autocomplete" ref="project-autocomplete" :resourceClass="resources.ProjectResource" @select="selectProject($event)"> </autocomplete>
                            </b-col>
                            <b-col class="col-1">
                                <b-button @click="clearAutocomplete('project-autocomplete')" variant="light">
                                    <b-icon icon="x-circle" variant="danger"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button @click="resetAllFilters" variant="danger">Reset <b-icon icon="arrow-counterclockwise"></b-icon></b-button>
                            </b-col>
                            <b-col class="d-flex justify-content-end">
                                <b-button variant="info" @click="searchForChecks">Search <b-icon icon="search"></b-icon></b-button>
                            </b-col>
                        </b-row>
                    </b-collapse>
                </b-col>
            </b-row>
        </b-container>
        <b-row v-for="newPr of newPrs" :key="newPr.prId">
            <b-col>
                <b-alert show variant="danger" class="pb-3">
                    <span class="clear-left">{{ newPr.adjustment }} successful. New payroll created: id {{ newPr.payroll }}. Credit payroll: {{ newPr.credit_payroll }} </span>
                    <span class="float-right ml-2">
                        <b-button @click="dismissMessage(newPr)" variant="danger" size="sm">
                            <b-icon icon="x-square-fill"></b-icon>
                        </b-button>
                    </span>
                    <span class="float-right pb-4">
                        <b-button @click="goToNewPr(newPr.payroll)" variant="primary" size="sm">
                            Go to payroll
                            <b-icon icon="arrow-right"></b-icon>
                        </b-button>
                    </span>
                </b-alert>
            </b-col>
        </b-row>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="changePage" aria-controls="payroll-list-table"> </b-pagination>
        <b-table v-if="checkListAttributes" :items="checkListAttributes" :fields="fields" :key="tableKey" ref="checkTable">
            <template #cell(adjustment)="data">
                <b-form-select v-model="data.value" :options="selectOptions" @change="selectAdjustment(data)"></b-form-select>
            </template>
            <template #cell(submit)="data">
                <b-button @click="submitAdjustment(data.item)" :disabled="loading">
                    Submit
                    <b-spinner v-if="data.item.loading" small></b-spinner>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
