<script>
import Vue from 'vue'
import BaseResource from '@sigmacloud/sigma-client/dist/resources/base'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import BatchResource from '@sigmacloud/sigma-client/dist/resources/transactions/batch'
import BankResource from '@sigmacloud/sigma-client/dist/resources/system/bank'
import CheckResource from '@sigmacloud/sigma-client/dist/resources/transactions/check'
import ClientResource from '@sigmacloud/sigma-client/dist/resources/system/client'
import AccountsPayableResource from '@sigmacloud/sigma-client/dist/resources/transactions/ap'
import ErrorsMixin from '../mixins/ErrorsMixins'
import ReportsMixins from '../mixins/ReportsMixins'
import UserMixin from '../mixins/UserMixin'
import CheckMixins from '../mixins/CheckMixins'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import ProjectNameCellRenderer from './ProjectNameCellRenderer.vue'
import ClientNameCellRenderer from './ClientNameCellRenderer.vue'
import BankCellRenderer from './BankCellRenderer.vue'
import Autocomplete from './Autocomplete.vue'
import CompanyAddress from './CompanyAddress.vue'
LicenseManager.setLicenseKey('CompanyName=Revolution Payroll,LicensedGroup=ProBooks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021824,ExpiryDate=4_December_2022_[v2]_MTY3MDExMjAwMDAwMA==0723da76405e8e4969df68bfe317209f')

export default Vue.extend({
    name: 'PrintPaychecks',
    data() {
        return {
            checkList: undefined,
            tableKey: 0,
            checkFields: ['id', 'description', 'payee_name', 'amount_decimal', 'payment_type', 'date', 'number', 'payroll', 'status'],
            selectedChecks: [],
            selectedCheckIds: [],
            checkRunId: undefined,
            checkPdf: undefined,
            loadingPdf: false,
            rows: undefined,
            currentPage: 1,
            loading: false,
            checksLoaded: false,
            scope: this,
            loaded: false,
            pageSize: 20,
            printBusy: false,
            startingCheckRange: undefined,
            quotedChecks: undefined,
            signBusy: false,
            downloadMsg: undefined,
            userId: undefined,
            userHasSignature: false,
            userSignatureId: undefined,
            signChecksCheckbox: false,
            overrideCompanyAddress: false,
            showAddressModal: false,
            selectedCompanyAddress: undefined,
            filterProps: {
                idFrom: undefined,
                idTo: undefined,
                client: undefined,
                project: undefined,
                endDateFrom: undefined,
                endDateTo: undefined,
                bank: undefined,
                batch: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                checkDateFrom: undefined,
                checkDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
            },
            gridOptions: {
                defaultColDef: {
                    resizable: true,
                    sortable: true,
                },
                undoRedoCellEditing: true,
                singleClickEdit: true,
                suppressAggFuncInHeader: true,
                suppressRowClickSelection: true,
                rowSelection: 'multiple',
                groupSelectsChildren: true,
                onGridReady: (params) => {
                    this.$data.scope.onGridReady(params)
                },
                onFirstDataRendered: (params) => {
                    this.$data.scope.onFirstDataRendered(params)
                },
                onCellValueChanged: (params) => {
                    this.$data.scope.onCellValueChanged(params)
                },
                defaultGroupSortComparator: (nodeA, nodeB) => {
                    if (nodeA.key > nodeB.key) {
                        return -1
                    } else if (nodeA.key < nodeB.key) {
                        return 1
                    } else {
                        return 0
                    }
                },
                onSelectionChanged: (params) => {
                    let selectedNodes = this.gridApi.getSelectedNodes()
                    this.numSelected = selectedNodes.length
                },
            },
            gridKey: 0,
            columnDefs: [],
            numSelected: 0,
            descriptionColumn: {
                headerName: 'Description',
                field: 'description',
                hide: false,
                width: 300,
                valueGetter: (params) => {
                    if (params && params.data && params.data.description) {
                        return params.data.description
                    } else {
                        return ''
                    }
                },
            },
            payeeNameColumn: {
                headerName: 'Payee Name',
                field: 'payee_name',
                hide: false,
                width: 250,
                valueGetter: (params) => {
                    if (params && params.data && params.data.payee_name) {
                        return params.data.payee_name
                    } else if (params.node && params.node.allChildrenCount === 1) {
                        if (params.node.allLeafChildren[0]) {
                            let { data } = params.node.allLeafChildren[0]
                            return data.payee_name
                        }
                    } else {
                        return ''
                    }
                },
            },
            amountColumn: {
                headerName: 'Amount',
                field: 'amount_decimal',
                hide: false,
                width: 200,
                aggFunc: function(params) {
                    let accumulator = 0
                    for (let amount of params) {
                        let nums
                        if (amount.charAt(0) === '$') {
                            nums = amount.substring(1)
                        } else {
                            nums = amount
                        }
                        accumulator += parseFloat(nums)
                    }
                    return `${accumulator.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                },
                valueGetter: (params) => {
                    if (params && params.data && params.data.amount_decimal) {
                        return `${params.data.amount_decimal}`
                    } else {
                        return '$0.00'
                    }
                },
                valueFormatter: (params) => {
                    return `$${params.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                },
            },
            payrollColumn: {
                headerName: 'Payroll ID',
                field: 'payroll',
                rowGroup: false,
                aggFunc: 'first',
                hide: false,
                width: 125,
                checkboxSelection: true,
                valueGetter: (params) => {
                    if (params && params.data && params.data.id) {
                        return params.data.id
                    } else {
                        return 0
                    }
                },
            },
            projectColumn: {
                headerName: 'Project',
                field: 'project',
                hide: false,
                width: 225,
                cellRenderer: 'projectCell',
            },
            clientColumn: {
                headerName: 'Client',
                // field: 'client',
                hide: false,
                width: 225,
                cellRenderer: 'clientCell',
            },
            bankColumn: {
                headerName: 'Bank',
                hide: false,
                width: 200,
                cellRenderer: 'bankCell',
            },
            payrollNumberColumn: {
                headerName: 'Payroll Number',
                field: 'number',
                hide: false,
                width: 200,
                valueGetter: (params) => {
                    return params.data.number
                },
            },

            dateColumn: {
                headerName: 'Pay Period End Date',
                field: 'pay_period_end_date',
                aggFunc: 'first',
                hide: false,
                width: 200,
                sortable: true,
                valueGetter: (params) => {
                    if (params && params.data && params.data.pay_period_end_date) {
                        return params.data.pay_period_end_date
                    } else {
                        return ''
                    }
                },
            },
            paymentTypeColumn: {
                headerName: 'Payment type',
                field: 'payment_type',
                hide: false,
                width: 150,
                valueGetter: (params) => {
                    if (params && params.data && params.data.payment_type) {
                        return params.data.payment_type
                    } else {
                        return ''
                    }
                },
                valueFormatter: (params) => {
                    let words = params.value.split('_')
                    const re = /(\b[a-z](?!\s))/g
                    let formattedWords = words
                        .join(' ')
                        .toLowerCase()
                        .replace(re, (x) => {
                            return x.toUpperCase()
                        })
                    return formattedWords
                },
            },
            statusColumn: {
                headerName: 'Status',
                field: 'status',
                hide: false,
                width: 150,
                valueGetter: (params) => {
                    if (params && params.data && params.data.status) {
                        return params.data.status
                    } else {
                        return ''
                    }
                },
            },
        }
    },

    mixins: [ErrorsMixin, ReportsMixins, UserMixin, CheckMixins],

    components: { AgGridVue, Autocomplete, CompanyAddress },

    props: {
        instanceGetter: Function,
        tokenGetter: Function,
    },

    computed: {
        printButtonEnabled() {
            return !this.printBusy && this.numSelected > 0
        },
        resourceClasses() {
            return {
                ClientResource,
                ProjectResource,
                BankResource,
                BatchResource,
            }
        },
    },

    methods: {
        emitError(error) {
            this.$emit('message', error)
            this.detailGridKey++
        },

        onGridReady(params) {
            if (params) {
                this.gridApi = params.api
                this.columnApi = params.columnApi
            }
        },

        onFirstDataRendered(params) {
            this.loaded = true
        },

        onCellValueChanged(params) {
            // Check if any change occurred so we can update the corresponding resource
            if (params.value && params.oldValue === params.newValue) return

            const rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex)

            // Mark data as changed for css purposes
            params.data.edited = true
            this.redrawCurrentRow(rowNode)

            this.setCellFocus()
        },

        async changePage(pageNum) {
            this.loading = true
            let paycheckResource = BaseResource.wrap(`/prs/printable?page_size=${this.pageSize}&page=${pageNum}`)
            try {
                let result = await paycheckResource.get()
                let checkList = result.data
                this.setTableData(checkList)
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },

        onSelectAddress(event) {
            this.selectedCompanyAddress = event
            this.showAddressModal = false
        },

        async printSignedOrUnsigned() {
            try {
                if (this.signChecksCheckbox) {
                    await this.signChecks()
                    return
                } else {
                    await this.printSelected()
                    return
                }
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async printSelected() {
            this.printBusy = true
            let selectedNodes = this.gridApi.getSelectedNodes()
            let selectedPayrolls = []
            let prIds = []
            for (let node of selectedNodes) {
                selectedPayrolls.push(node.data)
                prIds.push(node.data.id)
            }

            try {
                let groupedPayrolls = await this.groupPayrollsByBank(selectedPayrolls)
                let numberedChecks = await this.getCheckNumbers(groupedPayrolls)
                let savedChecks = await this.saveChecks(numberedChecks)
                let checkRunResponse = await this.saveCheckRun(savedChecks)
                for (let idVal of prIds) {
                    let idStr = String(idVal)
                    idVal = idStr
                }

                let reportObj = {
                    selected: 'Print Paychecks',
                    id: prIds,
                    override_company_address_id: this.overrideCompanyAddress ? this.selectedCompanyAddress : undefined,
                    data: {
                        id: prIds,
                    },
                    check_ids_to_print: checkRunResponse.data.checks,
                }

                if (prIds.length === 1) {
                    reportObj.id = prIds[0]
                    reportObj.data.id = prIds[0]
                }

                await this.getReport(reportObj)
                this.downloadMsg = "Your requested PDF has been downloaded.  Please look for it in your browser's recently downloaded files."
                setTimeout(() => {
                    this.downloadMsg = undefined
                }, 4000)
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.printBusy = false
            }
        },

        async getProjectName(projectId) {
            try {
                let projectResource = await ProjectResource.detail(projectId)
                if (projectResource) {
                    return projectResource.attributes.name
                }
            } catch (error) {
                this.$emit('message', error)
            }
        },

        getSelectedPayrolls() {
            let selectedRows = this.gridApi.getSelectedNodes()
            let selectedPayrolls = []
            for (let rowNode of selectedRows) {
                selectedPayrolls.push(rowNode.data.id)
            }
            return selectedPayrolls
        },

        async getApsForSelectedPayrolls(selectedPayrolls) {
            try {
                let apList = await AccountsPayableResource.filter({ payroll: selectedPayrolls })

                let selectedAps = []
                for (let resource of apList.resources) {
                    selectedAps.push(resource.id)
                }
                return selectedAps
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async getCheckIdsForSelectedAps(selectedAps) {
            try {
                let selectedChecks = []
                let checks = await CheckResource.filter({ related_transactions: selectedAps })
                for (let check of checks.resources) {
                    selectedChecks.push(check.id)
                }
                return selectedChecks
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async signChecks() {
            if (!this.userHasSignature || !this.userId) {
                return
            }

            this.printBusy = true

            try {
                // Get selected payroll ids
                let selectedPayrolls = this.getSelectedPayrolls()

                // Get APs for selected payrolls
                let selectedAps = await this.getApsForSelectedPayrolls(selectedPayrolls)

                // Get all check IDs for the selected APs
                let selectedChecks = await this.getCheckIdsForSelectedAps(selectedAps)

                // Request signatures for selected checks
                let currentTimestamp = new Date()
                let isoTime = currentTimestamp.toISOString()
                let checkApprovalData = {
                    paper_check: selectedChecks,
                    signature: this.userSignatureId,
                    request_type: 'SIGNATURE',
                    requested_by: this.userId,
                    requested_user: this.userId,
                    approved_on: isoTime,
                }

                await BaseResource.wrap('/checkapprovals').post(checkApprovalData)

                //approve signature requests
                for (let checkId of selectedChecks) {
                    await BaseResource.wrap(`/checks/${checkId}/approve`).post({
                        signature: this.userSignatureId,
                    })
                }

                let reportObj = {
                    selected: 'Print Paychecks',
                    id: selectedPayrolls,
                    data: {
                        id: selectedPayrolls,
                    },
                }

                if (selectedPayrolls.length === 1) {
                    reportObj.id = selectedPayrolls[0]
                    reportObj.data.id = selectedPayrolls[0]
                }

                await this.getReport(reportObj)
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.printBusy = false
            }
        },

        async getUserSignature() {
            //get signature for user
            try {
                let signatureResponse = await BaseResource.wrap('/signatures', { user: this.userId }).get()
                if (signatureResponse.data.count) {
                    let signatureObj = signatureResponse.data.results[0]
                    let signatureId = signatureObj.id
                    if (signatureId) {
                        this.userHasSignature = true
                        this.userSignatureId = signatureId
                    }
                } else {
                    this.userHasSignature = false
                }
            } catch (error) {
                this.$emit('message', error)
            }
        },

        setTableData(checkList) {
            this.rows = checkList.count || 0
            if (checkList.results && checkList.results.length) {
                let clAttrs = []
                for (let check of checkList.results) {
                    clAttrs.push(check)
                }
                this.checkList = clAttrs
                this.gridKey++
                this.checksLoaded = true
            }

            this.tableKey++
            this.gridKey++
        },

        setAutocompleteFilter(name, event) {
            this.filterProps[name] = event.id
        },

        async resetFilters() {
            this.filterProps = {
                idFrom: undefined,
                idTo: undefined,
                client: undefined,
                project: undefined,
                endDateFrom: undefined,
                endDateTo: undefined,
                bank: undefined,
                batch: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                checkDateFrom: undefined,
                checkDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
            }
            this.$refs.bankAutocomplete.debouncedInput = ''
            this.$refs.batchAutocomplete.debouncedInput = ''
            this.$refs.clientAutocomplete.debouncedInput = ''
            this.$refs.projectAutocomplete.debouncedInput = ''
            try {
                await this.filterSearchResults()
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async filterSearchResults() {
            let filters = {}
            if (this.filterProps.idFrom) {
                filters['id__gte'] = this.filterProps.idFrom
            }
            if (this.filterProps.idTo) {
                filters['id__lte'] = this.filterProps.idTo
            }
            // To get the client, search projects
            // if(this.filterProps.client){
            //     filters['client'] = this.filterProps.client
            // }
            if (this.filterProps.project) {
                filters['project'] = this.filterProps.project
            }
            if (this.filterProps.batch) {
                filters['batch'] = this.filterProps.batch
            }
            if (this.filterProps.bank) {
                filters['bank'] = this.filterProps.bank
            }
            if (this.filterProps.dueDateFrom) {
                filters['date_due__gte'] = this.filterProps.dueDateFrom
            }
            if (this.filterProps.dueDateTo) {
                filters['date_due__lte'] = this.filterProps.dueDateTo
            }
            if (this.filterProps.checkDateFrom) {
                filters['check_date__gte'] = this.filterProps.checkDateFrom
            }
            if (this.filterProps.checkDateTo) {
                filters['check_date__lte'] = this.filterProps.checkDateTo
            }
            if (this.filterProps.endDateFrom) {
                filters['pay_period_end_date__gte'] = this.filterProps.endDateFrom
            }
            if (this.filterProps.endDateTo) {
                filters['pay_period_end_date__lte'] = this.filterProps.endDateTo
            }
            if (this.filterProps.idNumFrom) {
                filters['id__gte'] = this.filterProps.idNumFrom
            }
            if (this.filterProps.idNumTo) {
                filters['id__lte'] = this.filterProps.idNumTo
            }

            let filterString = ''

            let keys = Object.keys(filters)
            for (let key of keys) {
                if (filters[key]) {
                    filterString += `${key}=${filters[key]}&`
                }
            }

            filterString = filterString.substring(0, filterString.length - 1)
            this.currentPage = 1
            this.loading = true
            let paycheckResource = BaseResource.wrap(`/prs/printable?page_size=${this.pageSize}&${filterString}`)
            try {
                let result = await paycheckResource.get()
                let checkList = result.data
                this.setTableData(checkList)
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },
        onCancelAddressModal() {
            this.showAddressModal = false
        },
    },

    beforeMount() {
        this.gridOptions.frameworkComponents = {
            projectCell: ProjectNameCellRenderer,
            clientCell: ClientNameCellRenderer,
            bankCell: BankCellRenderer,
        }
        this.gridOptions.context = this
        this.columnDefs.push(this.payrollColumn)
        this.columnDefs.push(this.payrollNumberColumn)
        this.columnDefs.push(this.projectColumn)
        this.columnDefs.push(this.clientColumn)
        this.columnDefs.push(this.descriptionColumn)
        this.columnDefs.push(this.dateColumn)
        this.columnDefs.push(this.statusColumn)
        this.columnDefs.push(this.bankColumn)
        // this.columnDefs.push(this.printColumn)

        this.gridKey++
    },

    // async mounted() {
    //     this.loading = true
    //     try {
    //         this.userId  = await this.getUserId()
    //         await this.getUserSignature()
    //         let paycheckResource = BaseResource.wrap(`/prs/printable?page_size=${this.pageSize}`)
    //         let result = await paycheckResource.get()
    //         let checkList = result.data
    //         this.setTableData(checkList)
    //     } catch (error) {
    //         this.$emit('message', error)
    //     } finally {
    //         this.loading = false
    //     }
    // }
})
</script>

<template>
    <div>
        <b-row>
            <b-col>
                <h2>Print Paychecks</h2>
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
            <b-spinner v-if="loading"></b-spinner>
        </b-row>
        <b-row cols="6" class="mb-2">
            <b-col>
                <b-button block href="#" v-b-toggle.accordion1 variant="secondary">
                    Search &amp; Filters
                    <span class="when-opened">
                        <b-icon icon="chevron-down" />
                    </span>
                    <span class="when-closed">
                        <b-icon icon="chevron-right" />
                    </span>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-collapse id="accordion1" visible role="tabpanel" class="mb-4">
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-1">
                            <label><strong>Payroll ID</strong></label>
                        </b-col>
                        <b-col class="d-flex justify-content-end col-sm-1">
                            <label>From</label>
                        </b-col>
                        <b-col>
                            <b-form-input v-model="filterProps.idFrom"></b-form-input>
                        </b-col>
                        <b-col class="d-flex justify-content-end col-sm-1">
                            <label>To</label>
                        </b-col>
                        <b-col>
                            <b-form-input v-model="filterProps.idTo"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-2">
                            <label><strong>Client</strong></label>
                        </b-col>
                        <b-col>
                            <autocomplete name="client-autocomplete" ref="clientAutocomplete" @select="setAutocompleteFilter('client', $event)" :resourceClass="resourceClasses.ClientResource"> </autocomplete>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label><strong>Project</strong></label>
                        </b-col>
                        <b-col class="col-sm-2">
                            <autocomplete name="project-autocomplete" ref="projectAutocomplete" @select="setAutocompleteFilter('project', $event)" :resourceClass="resourceClasses.ProjectResource"></autocomplete>
                        </b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-2">
                            <label><strong>Bank</strong></label>
                        </b-col>
                        <b-col class="col-sm-2 ">
                            <autocomplete name="bank-autocomplete" ref="bankAutocomplete" @select="setAutocompleteFilter('bank', $event)" :resourceClass="resourceClasses.BankResource"></autocomplete>
                        </b-col>

                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label><strong>Batch</strong></label>
                        </b-col>
                        <b-col class="col-sm-2">
                            <autocomplete name="batch-autocomplete" ref="batchAutocomplete" @select="setAutocompleteFilter('batch', $event)" :resourceClass="resourceClasses.BatchResource"></autocomplete>
                        </b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-1">
                            <label><strong>Period End Date</strong></label>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end"><label>From</label></b-col>
                        <b-col class="col-sm-2"><b-form-datepicker v-model="filterProps.endDateFrom"></b-form-datepicker></b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end"><label>To</label></b-col>
                        <b-col class="col-sm-2"><b-form-datepicker v-model="filterProps.endDateTo"></b-form-datepicker></b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-1">
                            <label><strong>Due Date</strong></label>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>From</label>
                        </b-col>
                        <b-col>
                            <b-form-datepicker v-model="filterProps.dueDateFrom"></b-form-datepicker>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>To</label>
                        </b-col>
                        <b-col>
                            <b-form-datepicker v-model="filterProps.dueDateTo"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-1">
                            <label><strong>Check Date</strong></label>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>From</label>
                        </b-col>
                        <b-col>
                            <b-form-datepicker v-model="filterProps.checkDateFrom"></b-form-datepicker>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>To</label>
                        </b-col>
                        <b-col>
                            <b-form-datepicker v-model="filterProps.checkDateTo"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row cols="6" class="mb-1">
                        <b-col class="col-sm-1">
                            <label><strong>ID Number</strong></label>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>From</label>
                        </b-col>
                        <b-col>
                            <b-form-input v-model="filterProps.idNumFrom"></b-form-input>
                        </b-col>
                        <b-col class="col-sm-1 d-flex justify-content-end">
                            <label>To</label>
                        </b-col>
                        <b-col>
                            <b-form-input v-model="filterProps.idNumTo"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row cols="6">
                        <b-col class="col-md-4"></b-col>
                        <b-col class="d-flex justify-content-end"><b-button variant="secondary" @click="resetFilters">Reset</b-button></b-col>
                        <b-col><b-button variant="info" @click="filterSearchResults">Filter</b-button></b-col>
                    </b-row>
                    <hr />
                </b-collapse>
            </b-col>
        </b-row>
        <b-row align-h="end" cols="6" class="mb-2" v-if="checkList">
            <b-col v-if="userHasSignature">
                <b-form-checkbox id="signature-checkbox" v-model="signChecksCheckbox" name="signature-checkbox" value="true" unchecked-value="false">
                    Add my signature
                </b-form-checkbox>
            </b-col>
            <b-col>
                <b-form-group>
                    <b-form-checkbox v-model="overrideCompanyAddress">Override company address</b-form-checkbox>
                    <b-button v-if="overrideCompanyAddress" :pressed.sync="showAddressModal">Select address</b-button>
                </b-form-group>
            </b-col>
            <b-col>
                <b-button v-if="printButtonEnabled" @click="printSignedOrUnsigned" variant="primary">Print Selected <b-icon icon="printer"></b-icon></b-button>
                <b-button v-if="!printButtonEnabled" disabled
                    >Print Selected&nbsp;
                    <b-spinner v-if="printBusy" small></b-spinner>
                </b-button>
            </b-col>
        </b-row>
        <div v-if="downloadMsg">
            <b-alert variant="info" show>{{ downloadMsg }}</b-alert>
        </div>
        <b-row v-if="checkList">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="pageSize" @change="changePage" aria-controls="payroll-list-table"></b-pagination>
            </b-col>
        </b-row>
        <b-row v-if="checkList">
            <b-col>
                <ag-grid-vue class="ag-theme-balham default-grid" :column-defs="columnDefs" :row-data="checkList" :grid-options="gridOptions" :key="gridKey" row-selection="multiple" />
            </b-col>
        </b-row>
        <b-modal size="lg" v-model="showAddressModal" title="Company Address" hide-footer>
            <company-address v-model="selectedCompanyAddress" @cancel="onCancelAddressModal" @selectedAddress="onSelectAddress"></company-address>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import '../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';

.default-grid {
    float: left;
    width: 100%;
    height: 700px;
}

.ag-theme-balham {
    .ag-root {
        border: none;
    }

    .ag-row {
        .ag-cell.editable {
            color: #3ea2ff !important;
        }
        &.ag-row-selected {
            border-color: #fffab7 !important;
            background-color: #fffab7;

            .ag-icon-checkbox-checked {
                color: #999999;
            }
        }
        &.ag-employee {
            font-weight: 900;
            background-color: #3ea2ff20;
        }
        &.ag-group {
            font-weight: 600;
            background-color: #cccccc50;
        }
        &.ag-changed {
            background-color: #e3fbe3;
        }
        &.hidden {
            .ag-cell {
                opacity: 0.38;
                font-style: italic;
            }
            background: inherit;
        }
    }
}

.ag-theme-balham .ag-ltr .ag-cell {
    color: #282828;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;

    &.amount,
    &.rate {
        text-align: right;
    }
    &.align-right {
        text-align: right;
    }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
