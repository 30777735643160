import Sigma from '@sigmacloud/sigma-client'
import crypto from 'crypto'

enum ReportGenerationState {
    FINISHED = 'finished',
    WAITING = 'waiting',
    RENDERING = 'rendering',
}

export interface ReportConstructorAttributes {
    name: string
    print_url: string
    render_url: string
    slug: string
}

export class Report {
    name: string
    printUrl: string
    renderUrl: string
    slug: string
    generationState?: ReportGenerationState
    generationPollingTimer?
    printing: boolean

    constructor(attributes: ReportConstructorAttributes) {
        this.name = attributes.name
        this.printUrl = attributes.print_url
        this.renderUrl = attributes.render_url
        this.slug = attributes.slug
        this.generationState = undefined
        this.printing = false
    }

    get isGenerating() {
        return this.generationState === ReportGenerationState.WAITING || this.generationState === ReportGenerationState.RENDERING
    }

    get isPrinting() {
        return this.printing
    }

    get generationFinished() {
        return this.generationState === ReportGenerationState.FINISHED
    }

    async regenerateUntilDone() {
        await this.regenerate()

        if (this.isGenerating) {
            this.generationPollingTimer = setInterval(async () => {
                await this.regenerate(false)

                if (!this.isGenerating) {
                    this.stopGenerationPolling()
                }
            }, 5000)
        }
    }

    async regenerate(force_refresh: boolean = true) {
        this.generationState = ReportGenerationState.WAITING

        try {
            let params = {}
            if (force_refresh) {
                params = { force_refresh: force_refresh }
            }
            const response = await Sigma.client.axios.get(this.renderUrl, { params: params })
            this.generationState = response.data.status

            if (this.generationFinished) {
                this.printUrl = response.data.print_url
            }
        } catch (error) {
            this.stopGenerationPolling()
            this.generationState = ReportGenerationState.FINISHED
            throw error
        }
    }

    stopGenerationPolling() {
        clearInterval(this.generationPollingTimer)
    }

    async print(payrollId: string) {
        this.printing = true
        const response = await Sigma.client.axios.get(this.printUrl, { responseType: 'arraybuffer' })
        const contentType = response.headers['content-type']
        let fileExtension = 'txt'
        if (contentType == 'text/csv') {
            fileExtension = 'csv'
        } else if (contentType == 'application/pdf') {
            fileExtension = 'pdf'
        }

        const pdfFile = response.data
        if (!pdfFile) {
            this.printing = false
            throw Error('Failed to print report. Please try again later.')
        }

        const objectUrl = URL.createObjectURL(new Blob([pdfFile], { type: fileExtension }))

        const randString = crypto.randomBytes(16).toString('hex')
        const fileName = `${payrollId}_${this.slug}_${randString}.${fileExtension}`

        const downloadLink = document.createElement('a')
        downloadLink.href = objectUrl
        downloadLink.download = fileName

        downloadLink.click()
        this.printing = false
    }
}
