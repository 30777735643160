import Vue from 'vue'

import { ValueGetterParams, ValueSetterParams, ValueFormatterParams } from '../../node_modules/ag-grid-community/dist/lib/entities/colDef'
import TimeEditLine from '../resources/TimeEditLine'
import CustomPayrollDetailLineResource from '../resources/customPayrollDetailLineResource'
import PayCodeResource from '@sigmacloud/sigma-client/dist/resources/system/paycode'
import FeeResource from '@sigmacloud/sigma-client/dist/resources/system/fee'
import GridAutocomplete from '../components/GridAutocomplete.vue'
import PayCodeCellEditor from '../components/PayCodeCellEditor.vue'
import SelectCellRenderer from '../components/SelectCellRenderer.vue'
import Autocomplete from '../components/Autocomplete.vue'
import CheckboxRenderer from '../components/CheckboxCellRenderer.vue'
import WorkAddressCellRenderer from '../components/WorkAddressCellRenderer.vue'

declare module 'vue/types/vue' {
    interface Vue {
        canSeeActuals: boolean
        customSum(Array): string
        customSumWithActuals(Array): string
        shouldObscureActuals(Object, string?): boolean
        isNotActuals(ValueGetterParams): string
        getCustomSumWithActualsRecord(ValueFormatterParams, number?): CustomSumWithActualsRecord
        timeEditTotalAmount: number
        generateTimeEditHoursColumns(): Array<Object>
    }
}

export interface CustomSumWithActualsRecord {
    value: string
    toString(): string
    isActuals: boolean
    excludeFromSum?: boolean
}

const baseAmountColumn = {
    headerName: 'Amount',
    field: 'amount_decimal',
    cellClass: 'amount',
    width: 90,
    valueGetter: (params: ValueGetterParams) => {
        const resource: CustomPayrollDetailLineResource = params.data

        if (!params.node.group && resource && resource.attributes) {
            return {
                value: String(resource.attributes.amount_decimal || ''),
                type: params.node.data.attributes.type,
                toString: () => {
                    return String(resource.attributes.amount_decimal || '')
                },
            }
        } else {
            return {
                value: params.node,
                toString: () => {
                    return ''
                },
            }
        }
    },
    valueSetter: (params: ValueSetterParams): void => {
        const resource = params.data
        if (resource) {
            const amount = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
            resource.set('amount_decimal', amount)
        }
    },
}

const baseRateColumn = {
    headerName: 'Rate',
    field: 'rate',
    cellClass: 'rate',
    width: 100,
    valueGetter: (params: ValueGetterParams): string => {
        const resource: CustomPayrollDetailLineResource = params.data
        if (resource && resource.attributes) return String(resource.attributes.hourly_unit_rate || '')
        else return '-'
    },
    valueSetter: (params: ValueSetterParams): void => {
        const resource: CustomPayrollDetailLineResource = params.data
        if (resource) {
            const rate = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(4)
            const hours = parseFloat(resource.attributes.hours_units || '0').toFixed(2)
            const factor = parseFloat(resource.attributes.factor || '1.0').toFixed(4)
            const amount = (parseFloat(rate) * parseFloat(hours) * parseFloat(factor)).toFixed(2)
            resource.set('hourly_unit_rate', rate)
            if (parseFloat(amount) > 0) resource.set('amount_decimal', amount)
        }
    },
}

const baseWorkDateColumn = {
    headerName: 'Work Date',
    field: 'work_date',
    width: 110,
    sortable: true,
    comparator: (a, b) => {
        const dateA = new Date(a)
        const dateB = new Date(b)
        return dateA.getTime() - dateB.getTime()
    },
    editable: (params: ValueGetterParams) => (params.data ? true : false),
    valueGetter: (params: ValueGetterParams): string => {
        const resource: CustomPayrollDetailLineResource = params.data
        if (resource) return String(resource.attributes.work_date || '')
    },
    valueSetter: (params: ValueSetterParams): void => {
        const resource: CustomPayrollDetailLineResource = params.data
        // TODO: add date formatter
        if (resource) resource.set('work_date', params.newValue)
    },
}

export default Vue.extend({
    components: {
        Autocomplete,
        CheckboxRenderer,
        SelectCellRenderer,
    },
    data() {
        return {
            canSeeActuals: false,
            timeEditTotalAmount: 0,
            numHrsColumns: 4,
            // Will never set via ag-grid.  Too many calculations are required so it's best to remove the employee and add a new one
            payrollEmployeeColumn: {
                headerName: 'Employee',
                field: 'employee',
                rowGroup: true,
                rowGroupIndex: 1,
                hide: true,
                width: 350,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    return resource.get('meta_name')
                },
            },

            payrollWorkDateGroupColumn: {
                headerName: 'Work Date',
                field: 'work_date_group',
                rowGroup: true,
                rowGroupIndex: 3,
                hide: true,
                width: 110,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    // if (resource) return String(resource.attributes.work_date || '')
                    return resource && resource.attributes.work_date ? String(resource.attributes.work_date) : String(resource.attributes.pay_period_end_date)
                },
            },

            // Pay/Fee Type Field.  Read-only
            payrollElementTypeColumn: {
                headerName: 'Type',
                field: 'type',
                rowGroup: true,
                rowGroupIndex: 2,
                sortable: false,
                sort: 'desc',
                width: 110,
                valueGetter: (params: ValueGetterParams): string => {
                    if (!params.data || !params.data.attributes) {
                        return
                    }

                    const resource = new CustomPayrollDetailLineResource(params.data.attributes)

                    if (resource.isActuals()) {
                        return 'ACTUALFEE'
                    } else if (params.data.attributes.type) {
                        return params.data.attributes.type
                    }
                },
            },

            // Combined Pay/Fee Field.  Read-only for fees, editable for pay codes
            payrollElementColumn: {
                headerName: 'Code',
                field: 'element',
                rowGroup: true,
                rowGroupIndex: 4,
                width: 150,
                hide: false,
                editable: (params: ValueGetterParams) => (params.data && params.data.attributes.fee ? false : true),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data

                    if (resource && resource.attributes.fee) {
                        return `${resource.attributes.fee_name}`
                    }
                    if (resource) return String(resource.attributes.pay_code_name)
                },
                cellEditorSelector: (params) => {
                    let resource: CustomPayrollDetailLineResource = params.data

                    if (resource && resource.attributes.fee_name) {
                        return {
                            component: 'agTextCellEditor',
                        }
                    }

                    return {
                        component: 'PayCodeCellEditor',
                    }
                },
            },

            payrollPayCodeColumn: {
                headerName: 'Pay Code',
                field: 'pay_code',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data && !params.data.attributes.fee ? true : false),
                /*
                valueGetter: (params: ValueGetterParams): string => {
                    console.log('ValueGetter')
                    console.log(params)
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.pay_code_name || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    console.log('ValueSetter')
                    console.log(params)
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) resource.set('pay_code', params.newValue)
                },
                */
                cellEditor: 'gridAutocomplete',
                cellEditorParams: {
                    resourceClass: PayCodeResource,
                    // useFormatter: false,
                    // values: this.getValues(this.getData())
                },
                /*
                valueFormatter: (params) => {
                    Vue.nextTick(() => {
                        console.log('ValueFormatter')
                        console.log(params)
                    })
                },
                // valueParser does not work with a cell editor
                valueParser: (params): string => {
                    console.log('ValueFormatter')
                    if (!params.newValue) return ''
                    console.log('ValueParser')
                    console.log(params)
                    let a = this.getData() || []
                    let b = a.find(datum => datum.name === params.newValue) || {}
                    console.log(b.code)
                    return b.code
                }
                */
            },

            mealPenaltyColumn: {
                headerName: 'Meal Pen',
                field: 'pay_code_21',
                width: 100,
                editable: (params: ValueGetterParams) => (params.data.attributes.pay_code_21 ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let lineData: TimeEditLine = params.data
                    if (lineData) return String(lineData.attributes.amount_21 || '')
                    else return ''
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let lineData: TimeEditLine = params.data
                    // console.log(lineData)
                    if (lineData) {
                        const hours = 0
                        let amount_21 = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                        let amount = 0
                        lineData.set('hours_units_21', hours.toFixed(2))
                        lineData.set('amount_21', amount_21)
                        for (let index = 1; index <= 25; index++) {
                            amount += parseFloat(lineData.attributes[`amount_${index}`]) || 0
                        }
                        lineData.set('amount_decimal', amount.toFixed(2))
                    }
                },
            },

            payrollFeeColumn: {
                headerName: 'Fee',
                field: 'fee',
                width: 100,
                hide: true,
                editable: (params: ValueGetterParams) => (params.data && !params.data.attributes.pay_code ? true : false),
                cellEditor: 'gridAutocomplete',
                cellEditorParams: {
                    resourceClass: FeeResource,
                },
            },

            payrollWorkDateColumn: baseWorkDateColumn,

            timeCardWorkDateColumn: {
                ...baseWorkDateColumn,
                aggFunc: () => 'Total',
            },

            payrollOccCodeColumn: {
                headerName: 'Occ Code',
                field: 'occ_code',
                editable: false,
                width: 110,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.occ_code || '')
                },
            },

            payrollPayAmountColumn: {
                headerName: 'Pay',
                field: 'pay',
                width: 110,
                aggFunc: this.customSum,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes.type === 'PAY') {
                        return String(resource.attributes.amount_decimal)
                    } else {
                        return undefined
                    }
                },
            },

            payrollERTaxAmountColumn: {
                headerName: 'ER Fee',
                field: 'er_fee',
                width: 110,
                aggFunc: this.customSumWithActuals,
                valueGetter: (params: ValueGetterParams): CustomSumWithActualsRecord => {
                    if (params.data.attributes && ['ERTAX', 'ERFEE'].includes(params.data.attributes.type)) {
                        return this.getCustomSumWithActualsRecord(params)
                    } else {
                        return undefined
                    }
                },
            },

            payrollEETaxAmountColumn: {
                headerName: 'EE DED',
                field: 'ee_ded',
                width: 110,
                aggFunc: this.customSumWithActuals,
                valueGetter: (params: ValueGetterParams): CustomSumWithActualsRecord => {
                    if (params.data.attributes && ['EETAX', 'DEDUCTION', 'GARNISHMENT'].includes(params.data.attributes.type)) {
                        return this.getCustomSumWithActualsRecord(params)
                    } else {
                        return undefined
                    }
                },
            },

            payrollEmployerCostAmountColumn: {
                headerName: 'ER Cost',
                field: 'er_cost',
                width: 110,
                aggFunc: this.customSumWithActuals,
                valueGetter: (params: ValueGetterParams): CustomSumWithActualsRecord => {
                    if (params.data.attributes && ['PAY', 'ERTAX', 'ERFEE'].includes(params.data.attributes.type)) {
                        return this.getCustomSumWithActualsRecord(params)
                    } else {
                        return undefined
                    }
                },
            },

            payrollEENetAmountColumn: {
                headerName: 'EE Net',
                field: 'ee_net',
                width: 110,
                aggFunc: this.customSumWithActuals,
                valueGetter: (params: ValueGetterParams): CustomSumWithActualsRecord => {
                    if (params.data && params.data.attributes.type === 'PAY') {
                        return this.getCustomSumWithActualsRecord(params)
                    } else if (params.data && ['EETAX', 'DEDUCTION', 'GARNISHMENT'].includes(params.data.attributes.type)) {
                        return this.getCustomSumWithActualsRecord(params, -1)
                    } else {
                        return undefined
                    }
                },
            },

            payrollWorkDayOfWeekColumn: {
                headerName: 'Day of Week',
                field: 'day_of_week',
                width: 115,
                editable: false,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.day_of_week || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    // TODO: add date formatter
                    if (resource) resource.set('day_of_week', params.newValue)
                },
            },

            payrollHoursColumn: {
                headerName: 'Hours',
                colId: 'hours_units',
                aggFunc: (params) => {
                    const firstValue = params[0]

                    if (this.shouldObscureActuals(firstValue, 'resource')) {
                        return '*.**'
                    } else if (typeof firstValue === 'object') {
                        const resource = new CustomPayrollDetailLineResource(firstValue.resource.attributes)

                        if (resource.isActuals()) {
                            return
                        }

                        if (firstValue.resource.attributes.type === 'PAY') {
                            return params.reduce((totalHours, hours) => totalHours + parseFloat(hours.value), 0.0).toFixed(2)
                        }
                    } else if (typeof firstValue === 'string') {
                        let payHours = params.reduce((totalHours, hours) => totalHours + parseFloat(hours), 0.0)
                        return isNaN(payHours) ? undefined : payHours.toFixed(2)
                    }
                    return '0.00'
                },
                width: 80,
                editable: (params: ValueGetterParams) => params.data && !this.shouldObscureActuals(params),
                valueGetter: (params) => {
                    let resource = params.data
                    if (resource && resource.attributes) {
                        return {
                            value: String(resource.attributes.hours_units || '0.00'),
                            resource,
                            toString() {
                                return this.value
                            },
                        }
                    }
                    return ''
                },
                valueSetter: (params) => {
                    let resource = params.data
                    if (resource) {
                        if (isNaN(params.newValue) || params.newValue === params.oldValue.value) {
                            return
                        }
                        // We are going to multiply rate * hours to get an amount
                        const hours = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                        const rate = parseFloat(resource.attributes.hourly_unit_rate || '0').toFixed(4)
                        const factor = parseFloat(resource.attributes.factor || '1.0').toFixed(4)
                        const amount = (parseFloat(rate) * parseFloat(hours) * parseFloat(factor)).toFixed(2)
                        resource.set('amount_decimal', amount)
                        resource.set('hours_units', hours)
                    }
                },
                valueFormatter: (params) => {
                    if (this.shouldObscureActuals(params)) {
                        return '*.**'
                    }

                    if (typeof params === 'object' && params.value) {
                        if (isNaN(parseFloat(params.value.value))) {
                            return undefined
                        }
                        return params.value.value
                    }
                },
            },

            payrollRateColumn: {
                ...baseRateColumn,
                aggFunc: 'first',
                editable: (params: ValueGetterParams) => (params.data ? true : false),
            },

            timeCardRateColumn: {
                ...baseRateColumn,
                aggFunc: () => '',
                editable: this.isNotActuals,
            },

            payrollScaleRateColumn: {
                headerName: 'Scale Rate',
                field: 'scale_rate_decimal',
                cellClass: 'scaleRate',
                aggFunc: 'first',
                width: 120,
                editable: this.isNotActuals,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.scale_rate_decimal || '')
                    else return '-'
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) {
                        // We are going to multiple rate * hours to get an amount
                        const scale_rate = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(4)
                        resource.set('scale_rate_decimal', scale_rate)
                    }
                },
            },

            payrollAmountColumn: {
                ...baseAmountColumn,
                aggFunc: (nodes) => {
                    let sum = 0
                    if (nodes && nodes.length && nodes[0].value) {
                        for (let node of nodes) {
                            if (node.value && node.value.allLeafChildren) {
                                let leafChildren = node.value.allLeafChildren
                                if (leafChildren.length && leafChildren[0].data) {
                                    for (let leafChild of leafChildren) {
                                        if (leafChild.data.attributes.type === 'PAY' || leafChild.data.attributes.type === 'FEE') {
                                            sum += parseFloat(leafChild.data.attributes.amount_decimal)
                                        }
                                    }
                                }
                            } else if (node.value && node.type) {
                                if (node.type === 'PAY' || node.type == 'FEE') {
                                    sum += parseFloat(node.value)
                                }
                            } else if (typeof node === 'string') {
                                sum += parseFloat(node)
                            } else {
                                return
                            }
                        }
                    } else {
                        let tlSum = 0
                        for (let num of nodes) {
                            if (!isNaN(parseFloat(num))) {
                                tlSum += parseFloat(num)
                            }
                        }
                        return tlSum.toFixed(2)
                    }
                    // else if (nodes && nodes.length && nodes[0].value && nodes[0].type === 'PAY') {
                    //     nodes.forEach((node) => {
                    //         sum += parseFloat(node.value)
                    //     })
                    // }
                    if (sum === 0) {
                        return undefined
                    } else if (typeof sum === 'number') {
                        return sum.toFixed(2)
                    }
                },
                width: 90,
                editable: (params: ValueGetterParams) => params.data && !this.shouldObscureActuals(params),
                valueFormatter: (params) => {
                    if (this.shouldObscureActuals(params)) {
                        return '$*.**'
                    }

                    if (typeof params.value === 'string') {
                        return '$' + params.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    } else {
                        return params.value
                    }
                },
            },

            timeCardAmountColumn: {
                ...baseAmountColumn,
                aggFunc: (entries) => {
                    const sum = this.customSum(entries.map((entry) => entry.value))

                    const floatSum = parseFloat(sum)
                    this.timeEditTotalAmount = isNaN(floatSum) ? 0 : floatSum

                    return sum ? sum : '-'
                },
            },

            overrideColumn: {
                headerName: 'Override',
                field: 'override',
                cellRenderer: 'checkboxRenderer',
                showRowGroup: false,
                width: 95,
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return resource.attributes.override
                },
                valueSetter: (params: ValueSetterParams): void => {
                    if (params.node) {
                        let resource: CustomPayrollDetailLineResource = params.data
                        if (resource && resource.attributes) {
                            resource.set('override', params.newValue)
                            params.newValue && params.newValue !== params.oldValue ? resource.set('status', 'EDITED') : resource.set('status', 'NONE')
                        }
                    }
                },
            },

            /*
            payrollHourInputColumn: {
                headerName: 'Hours',
                field: 'hours_units',
                cellClass: 'amount',
                aggFunc: customSum,
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.hours_units || '')
                    else return ''
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) {
                        // We are going to multiple rate * hours to get an amount
                        const hours = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                        const rate = parseFloat(resource.attributes.hourly_unit_rate || '0').toFixed(4)
                        const amount = (parseFloat(rate) * parseFloat(hours)).toFixed(2)
                        resource.set('hours_units', hours)
                        if (parseFloat(amount) > 0) resource.set('amount_decimal', amount)
                    }
                },
            },

            payrollRateInputColumn: {
                headerName: 'Rate',
                field: 'rate',
                cellClass: 'rate',
                aggFunc: 'first',
                width: 100,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.hourly_unit_rate || '')
                    else return '-'
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) {
                        // We are going to multiple rate * hours to get an amount
                        const rate = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(4)
                        const hours = parseFloat(resource.attributes.hours_units || '0').toFixed(2)
                        const amount = (parseFloat(rate) * parseFloat(hours)).toFixed(2)
                        resource.set('hourly_unit_rate', rate)
                        if (parseFloat(amount) > 0) resource.set('amount_decimal', amount)
                    }
                },
            },

            payrollAmountInputColumn: {
                headerName: 'Amount',
                field: 'amount_decimal',
                cellClass: 'amount',
                aggFunc: customSum,
                width: 100,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.amount_decimal || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: PayrollDetailLineResource = params.data
                    if (resource) {
                        const amount = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                        resource.set('amount_decimal', amount)
                    }
                },
            },
            */

            clientAccountColumn: {
                headerName: 'Acct',
                field: 'customer_budget_code',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueFormatter: (params) => {
                    if (params.value.charAt(0) === '\uFF10') {
                        let displayString = params.value.replace('\uFF10', '0')
                        return displayString
                    }
                },
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) {
                        let acctString = resource.attributes.customer_budget_code
                        if (acctString === undefined || acctString == null || acctString === '') {
                            acctString = ''
                        } else if (acctString.charAt(0) === '0') {
                            acctString = acctString.replace('0', '\uFF10')
                        }
                        return acctString
                    } else {
                        return ''
                    }
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) {
                        let nv = String(params.newValue)
                        if (nv.charAt(0) == '\uFF10') {
                            nv = params.newValue.replace('\uFF10', '0')
                        }
                        resource.set('customer_budget_code', nv)
                    }
                },
            },

            clientFringeColumn: {
                headerName: 'Frng',
                field: 'customer_fringe_code',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_fringe_code || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_fringe_code', params.newValue)
                },
            },

            clientHandlingFeeColumn: {
                headerName: 'HF',
                field: 'customer_hf_code',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_hf_code || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_hf_code', params.newValue)
                },
            },

            clientSeriesColumn: {
                headerName: 'Ser',
                field: 'customer_series',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_series || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_series', params.newValue)
                },
            },

            clientLocationColumn: {
                headerName: 'Loc',
                field: 'customer_location',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_location || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_location', params.newValue)
                },
            },

            clientSetColumn: {
                headerName: 'Set',
                field: 'customer_set',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_set || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_set', params.newValue)
                },
            },

            clientFF1Column: {
                headerName: 'FF1',
                field: 'customer_ff1',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_ff1 || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_ff1', params.newValue)
                },
            },

            clientFF2Column: {
                headerName: 'FF2',
                field: 'customer_ff2',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_ff2 || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_ff2', params.newValue)
                },
            },

            clientFF3Column: {
                headerName: 'FF3',
                field: 'customer_ff3',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) return String(resource.attributes.customer_ff3 || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_ff3', params.newValue)
                },
            },

            clientFF4Column: {
                headerName: 'FF4',
                field: 'customer_ff4',
                width: 80,
                editable: (params: ValueGetterParams) => (params.data ? true : false),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.customer_ff4 || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource) resource.set('customer_ff4', params.newValue)
                },
            },

            payrollSubjectWagesColumn: {
                headerName: 'Sub Wage',
                field: 'subject_wages_decimal',
                cellClass: 'amount',
                width: 100,
                valueGetter: (params: ValueGetterParams): string => {
                    if (this.shouldObscureActuals(params)) {
                        return '*.**'
                    }

                    const resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.subject_wages_decimal || '')
                },
            },

            payrollSubjectHoursColumn: {
                headerName: 'Sub Hour',
                field: 'subject_hours_units',
                cellClass: 'amount',
                width: 100,
                valueGetter: (params: ValueGetterParams): string => {
                    if (this.shouldObscureActuals(params)) {
                        return '*.**'
                    }

                    const resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.subject_hours_units || '')
                },
            },

            payrollPensionHoursColumn: {
                headerName: 'Pen Hour',
                field: 'pension_hours_units',
                cellClass: 'amount',
                width: 100,
                editable: (params: ValueGetterParams) => params.data && !this.shouldObscureActuals(params),
                valueGetter: (params: ValueGetterParams): string => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    if (resource && resource.attributes) return String(resource.attributes.pension_hours_units || '')
                },
                valueSetter: (params: ValueSetterParams): void => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    let formattedHrs = parseFloat(params.newValue).toFixed(2)
                    if (resource) resource.set('pension_hours_units', formattedHrs)
                },
                valueFormatter: (params) => {
                    if (this.shouldObscureActuals(params)) {
                        return '*.**'
                    }
                },
            },

            ...this.generateTimeEditHoursColumns(),

            workAddressColumn: {
                headerName: 'Work Address',
                field: 'work_address',
                agg_func: 'first',
                valueGetter: (params: ValueGetterParams): string => {
                    if (!params.data) {
                        return
                    }

                    const addressId = params.data.attributes.work_address
                    if (addressId) {
                        return String(addressId)
                    } else {
                        return undefined
                    }
                },
                cellRenderer: 'selectCellRenderer',
            },

            payrollWorkAddressColumn: {
                headerName: 'Work Address',
                field: 'work_address',
                cellRenderer: 'selectCellRenderer',
                aggFunc: 'first',
                valueGetter: (params) => {
                    let resource: CustomPayrollDetailLineResource = params.data
                    return resource.attributes.work_address
                },
            },
        }
    },

    methods: {
        getValues(data: Array<any>): Array<any> {
            return data.map((record) => record.code)
        },
        getData(): Array<any> {
            return [
                { name: 'Ireland', code: 'IE' },
                { name: 'UK', code: 'UK' },
                { name: 'France', code: 'FR' },
            ]
        },
        // Ag-grid Helpers.  Could belong in an AG-Grid library
        getSelectedRows(): void {
            const selectedNodes = this.gridApi.getSelectedNodes()
            const selectedData = selectedNodes.map((node) => node.data)
            const selectedDataStringPresentation = selectedData.map((node) => node.make + ' ' + node.model).join(', ')
            alert(`Selected nodes: ${selectedDataStringPresentation}`)
        },

        // Redraw rows, useful to show changes and other styles
        redrawCurrentRow(rowNode): void {
            if (!rowNode) {
                const cell = this.gridApi.getFocusedCell()
                rowNode = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex)
            }
            if (!rowNode) return
            // console.log(rowNode)
            this.gridApi.redrawRows({ rowNodes: [rowNode] })
        },

        changeGrouping(expand: boolean = true): void {
            if (this.groupingLevel < 0) this.groupingLevel = 0

            this.gridApi.forEachNode((node) => {
                if (expand === true && node.level <= this.groupingLevel && ((node.level > 0 && node.group) || (node.data && node.data.attributes.type === 'PAY'))) {
                    node.setExpanded(true)
                } else if (expand === false) {
                    node.setExpanded(false)
                    if (node.level < this.groupingLevel - 1) {
                        node.setExpanded(true)
                    }
                }
            })
            if (expand === true) {
                this.groupingLevel++
            } else if (expand === false) {
                this.groupingLevel--
            }
        },

        expandAll(): void {
            this.gridApi.expandAll()
        },

        collapseAll(): void {
            this.gridApi.collapseAll()
        },

        // Test function
        log(value: any): void {
            console.log(value)
        },

        customSum(values: Array<string>): string {
            let sum = 0
            values
                .filter((value) => value !== '*.**')
                .forEach((value) => {
                    if (value) sum += parseFloat(value)
                })
            if (isNaN(sum) || sum === 0) {
                return ''
            } else {
                return sum.toFixed(2)
            }
        },

        customSumWithActuals(values: Array<CustomSumWithActualsRecord>) {
            const nonEmptyValues = values.filter((v) => v)

            if (nonEmptyValues.length && nonEmptyValues.every((v) => v.isActuals && !v.excludeFromSum)) {
                const value = this.customSum(nonEmptyValues.map((v) => v.value))
                return { toString: () => (this.canSeeActuals ? value : '*.**'), value, isActuals: true, excludeFromSum: true }
            } else {
                const value = this.customSum(nonEmptyValues.filter((v) => v && !v.isActuals).map((v) => v.value))
                return { toString: () => value, value }
            }
        },

        // Send focus back to next cell
        setCellFocus(cell: any): void {
            if (!cell) cell = this.gridApi.getFocusedCell()

            if (cell) this.gridApi.setFocusedCell(cell.rowIndex, cell.column)
        },

        CountryCellRenderer(params) {
            console.log('Renderer')
            console.log(params)
            return params.value.name
        },

        CustomCellEditor(params) {
            console.log(params)
        },

        shouldObscureActuals(params, dataKey: string = 'data') {
            if (params && typeof params[dataKey] === 'object') {
                const resource = new CustomPayrollDetailLineResource(params[dataKey].attributes)

                if (resource && resource.isActuals() && !this.canSeeActuals) {
                    return true
                }
            }

            return false
        },

        isNotActuals(params: ValueFormatterParams) {
            if (!params.data || !params.data.attributes) {
                return true
            }

            const resource = new CustomPayrollDetailLineResource(params.data.attributes)
            return !resource.isActuals()
        },

        getCustomSumWithActualsRecord(params: ValueGetterParams, multiplier?: number): CustomSumWithActualsRecord {
            const resource = new CustomPayrollDetailLineResource(params.data.attributes)
            const value: string = multiplier ? String(multiplier * parseFloat(resource.attributes.amount_decimal)) : resource.attributes.amount_decimal

            return {
                toString: () => (this.shouldObscureActuals(params) ? '*.**' : value),
                isActuals: resource.isActuals(),
                value,
            }
        },
        generateTimeEditHoursColumns() {
            const columns = {}

            Array.from({ length: 20 }, (_, index) => index + 1).forEach((index) => {
                columns[`timeEditHoursColumn${index}`] = {
                    headerName: `Hrs${index}`,
                    field: `hours_units_${index}`,
                    cellClass: 'amount',
                    aggFunc: this.customSum,
                    width: 120,
                    editable: (params: ValueGetterParams) => (params.data.attributes[`pay_code_${index}`] ? true : false),
                    valueGetter: (params: ValueGetterParams): string => {
                        const lineData: TimeEditLine = params.data
                        let hourly = true
                        if (lineData && lineData.attributes && lineData.attributes[`pay_code_${index}`]) {
                            const hnus = lineData.attributes[`pay_code_${index}`].get('hnus')
                            if (hnus === 'NONHOURLY') {
                                hourly = false
                            }
                            if (hourly) {
                                return String(lineData.attributes[`hours_units_${index}`] || '')
                            } else {
                                return String(lineData.attributes[`amount_${index}`] || '')
                            }
                        } else {
                            return ''
                        }
                    },
                    valueSetter: (params: ValueSetterParams): void => {
                        const lineData: TimeEditLine = params.data
                        if (lineData) {
                            const rate = parseFloat(lineData.attributes.hourly_unit_rate || '0').toFixed(4)
                            const factor = parseFloat(lineData.attributes[`pay_code_${index}`].get('factor'))
                            let hours = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                            let hourly = true
                            const hnus = lineData.attributes[`pay_code_${index}`].get('hnus')
                            if (hnus === 'NONHOURLY') {
                                hourly = false
                            }
                            let amountForIndex = '0.00'
                            if (hourly) {
                                amountForIndex = (parseFloat(rate) * factor * parseFloat(hours)).toFixed(2)
                            } else {
                                amountForIndex = hours
                                hours = '0.00'
                            }
                            lineData.set(`hours_units_${index}`, hours)
                            lineData.set(`amount_${index}`, amountForIndex)

                            let amountDecimal = 0
                            for (let index = 1; index <= 25; index++) {
                                amountDecimal += parseFloat(lineData.attributes[`amount_${index}`]) || 0
                            }
                            lineData.set('amount_decimal', amountDecimal.toFixed(2))
                        }
                    },
                }
            })

            return columns
        },
    },

    beforeMount() {
        this.$data.gridOptions.frameworkComponents = {
            countryCellRenderer: this.CountryCellRenderer,
            gridAutocomplete: GridAutocomplete,
            checkboxRenderer: CheckboxRenderer,
            selectCellRenderer: SelectCellRenderer,
            workAddressCellRenderer: WorkAddressCellRenderer,
            PayCodeCellEditor,
        }
        // this.$data.gridOptions.groupRowAggNodes = groupRowAggNodes
    },
})
