const ADJUSTMENTS: { name: string; endpoint: string }[] = [
    {
        name: 'Void & Reissue',
        endpoint: '/void_reissue',
    },
    {
        name: 'Straight Void',
        endpoint: '/void_credit',
    },
]

const VOID_REISSUE: string = 'Void & Reissue'
const STRAIGHT_VOID: string = 'Straight Void'

const ADJUSTMENTS_OPT: string[] = [VOID_REISSUE, STRAIGHT_VOID]

export default {
    ADJUSTMENTS,
    ADJUSTMENTS_OPT,
    VOID_REISSUE,
    STRAIGHT_VOID,
}
